
















































import {Component, Vue} from "vue-property-decorator";
import SendForm9783TPayload from "@/dto/signature/SendForm9783TPayload";
import PortalInput from "@/components/common/PortalInput.vue";
import SimpleProfileSearch from "@/components/common/SimpleProfileSearch.vue";
import PersonDTO from "@/dto/person/PersonDTO";
import PersonService from "@/services/PersonService";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {processError} from "@/utils/ComponentUtils";
import CompanyService from "@/services/CompanyService";
import CompanyDTO from "@/dto/company/CompanyDTO";
import PersonSearch from "@/components/common/PersonSearch.vue";
import {MaritalStatus} from "@/dto/taxation/individual/MaritalStatus.ts";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import {WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import DocumentService from "@/services/DocumentService";
import Application from "@/state/Application";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import {BankAccountType} from "@/dto/BankAccountType";
import Notifications from "@/state/Notifications";
import BankAccountDTO from "@/dto/BankAccountDTO";


@Component<Form9783T>({
  computed: {
    WorkspaceTypePathElement() {
      return WorkspaceTypePathElement
    }
  },
  components: {PortalSelect, SimpleCompanySearch, PersonSearch, PortalCheckbox, SimpleProfileSearch, PortalInput}
})
export default class Form9783T extends Vue {
  public MARITAL_STATUS = MaritalStatus;
  public payload = new SendForm9783TPayload();
  public taxpayer = new PersonDTO();
  public company = new CompanyDTO();
  bankAccounts: Array<BankAccountDTO> = [];
  bankAccountSelectOptions: Array<SelectOption> = [];
  selectedAccount: BankAccountDTO | null = null;

  loadTaxpayer(profile: PersonDTO): void {
    Application.startLoading();
    PersonService.getById(profile.id as number).then(
        ok => {
          this.taxpayer = ok.data;
          this.payload.personId = this.taxpayer.id;
          this.payload.taxpayerSsn = this.taxpayer.ssn;
          this.payload.taxpayerName = this.taxpayer.name;
          this.payload.taxpayerAddress = this.taxpayer.address?.address1 as string + " " + (this.taxpayer.address?.address2 ? this.taxpayer.address?.address2 as string : '');
          this.payload.taxpayerCity = this.taxpayer.address?.city as string;
          this.payload.taxpayerState = this.taxpayer.address?.state as string;
          this.payload.taxpayerZip = this.taxpayer.address?.zipCode as string;
          this.payload.jointFilerSsn = this.taxpayer.spouse?.ssn as string;
          Application.stopLoading();
          this.loadAccounts();
        },
        err => {
          processError(err, this);
        }
    );
  }

  loadCompany(profile: CompanyDTO): void {
    Application.startLoading();
    CompanyService.getById(profile.id).then(
        ok => {
          this.company = ok.data;
          this.payload.thirdPartyName = this.company.name;
          this.payload.thirdPartyMailingAddress = this.company.mailingAddress?.address1 as string + " " + (this.company.mailingAddress?.address2 ? this.company.mailingAddress?.address2 as string : '');
          this.payload.thirdPartyCity = this.company.mailingAddress?.city as string;
          this.payload.thirdPartyState = this.company.mailingAddress?.state as string;
          this.payload.thirdPartyZip = this.company.mailingAddress?.zipCode as string;
          this.payload.thirdPartyPhone = this.company.phone;
          Application.stopLoading();
        },
        err => processError(err, this)
    );
  }

  private buildAccountTypeOptions(): Array<SelectOption> {
    return [
      SelectOption.builder().title(BankAccountType.CHECKING).value(BankAccountType.CHECKING).build(),
      SelectOption.builder().title(BankAccountType.SAVING).value(BankAccountType.SAVING).build(),
    ]
  }

  sendDocument(payload: SendForm9783TPayload): void {
    Application.startLoading()
    payload = this.payload;
    DocumentService.sendForm9783T(payload).then(
        () => {
          Application.stopLoading()
          Notifications.success("The document has been sent.");
          this.$router.push({name: this.$routeNames.ARCHIVE_SIGNABLE_LIST_NEW})
        },
        err => {
          processError(err, this)
        }
    )
  }

  loadAccounts(){
    Application.startLoading();
    PersonService.getBankAccounts(this.payload.personId!).then(
        ok => {
          this.bankAccounts = ok.data;
          this.bankAccountSelectOptions = ok.data.map(acc => new SelectOption({
            title: acc.accountNumber + " " + acc.accountType,
            value: acc,
          }))
          Application.stopLoading();
        },
        err => processError(err, this)
    )
  }

  accountChanged(){
    if (this.selectedAccount) {
      this.payload.rtn = this.selectedAccount.routingNumber;
      this.payload.accountType = this.selectedAccount.accountType;
      this.payload.accountNumber = this.selectedAccount.accountNumber;
    }
  }

}
