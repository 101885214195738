import {BankAccountType} from "@/dto/BankAccountType";

export default class SendForm9783TPayload{
    personId: number | null = null;
    filingWithSpouse: boolean | null = null;
    taxpayerSsn: string | null = null;
    taxpayerName: string | null = null;
    jointFilerSsn: string | null = null;
    taxpayerAddress: string | null = null;
    taxpayerCity: string | null = null;
    taxpayerState: string | null = null;
    taxpayerZip: string | null = null;

    thirdPartyName: string | null = null;
    batchProviderRegistrationNumber: string | null = null;
    thirdPartyMailingAddress: string | null = null;
    thirdPartyCity: string | null = null;
    thirdPartyState: string | null = null;
    thirdPartyZip: string | null = null;
    thirdPartyPhone: string | null = null;

    rtn: string | null = null;
    accountNumber: string | null = null;
    accountType: BankAccountType | null = null;
}